import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    {
      tgs2: file(relativePath: { eq: "pand-tgs-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 1035) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <Img
            className="object-cover w-full h-56 lg:absolute lg:h-full"
            fluid={data.tgs2.childImageSharp.fluid}
            alt=""
          />
        </div>
      </div>
      <div className="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="mx-auto text-base max-w-prose lg:max-w-lg lg:ml-auto lg:mr-0">
            <p className="font-semibold leading-6 tracking-wide text-orange-500 uppercase">
              Experience makes quality
            </p>
            <h2 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Working with TGS means certainty
            </h2>
            <p className="mb-5 text-lg leading-7 text-gray-500">
              Nearly every matter or issue in the field of finance, tax and
              legal belongs to our daily activities. It’s integrated in our
              day-to-day routine, which results in a broad know-how of helping
              (foreign) entrepreneurs set-up, maintain or expand their
              business(es) in the Netherlands.
            </p>
            <div className="prose text-gray-500">
              <p>
                We know the ins and outs of all tax-related Dutch laws and
                regulations. The outcome is an extreme certainty in quality. For
                whatever service you need. Whether it’s tax accounting,
                optimizing debt finance, advisory in the field of your tax
                filings or something completely different. Our experienced team
                of tax and legal professionals get the job done.
              </p>
              <h2>Personal, tailored and accurate</h2>
              <p>
                Every client is different. Just like every assignment differs
                from the one before. That’s why we don’t work from one
                particular formula. On the contrary; for each client or
                assignment, we design a tailor-made solution that perfectly
                suits your situation. Whether it comes to (corporate) tax, VAT,
                compliance or expat related matters. We are here to help you
                out.
              </p>
              <p>Always personal, always tailored, always accurate.</p>
              <h2>Proud member of TGS Global</h2>
              <p>
                We aren’t the only TGS establishment in the world. TGS lime tree
                is a member of TGS Global, which means that we can rely and
                bulld on the knowledge of 69 members in 57 countries, spread
                over 225 hubs worldwide. Our global partnership results in a
                worldwide network of professionals in many, many fields.
              </p>
              <p>
                Did you partner up with our office in Amsterdam, but are you in
                need of a service somewhere else in the world? The perfect
                solution is just one phone call away. TGS Global exists out of a
                dynamic team of professionals with expertise in advisory, audit,
                law and accounting.
              </p>
              <p>
                Say goodbye to a confusing string of incoherent partners, say
                hello to one trustworthy partner established all over the globe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
