import React from "react"
import { Helmet } from "react-helmet"
import Hero from "../components/Hero"
import Features from "../components/Features"
import Stats from "../components/Stats"
import FAQ from "../components/FAQ"
import Team from "../components/Team"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Content from "../components/Content"

const Index = () => {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>
          TGS lime tree: Your full-service Tax Partner in the Netherlands
        </title>
        <meta
          name="description"
          content="From the heart of Amsterdam, we provide services to small- and medium-sized companies that wish to set foot or expand in Europe or the Netherlands."
        />
        <link rel="canonical" href="https://www.taxamsterdam.com/" />
        <link
          rel="alternate"
          href="https://www.taxamsterdam.com/"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://www.taxamsterdam.com/de/"
          hreflang="de"
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://www.taxamsterdam.com/"
        />
      </Helmet>
      <Hero />
      <Features />
      <Stats />
      <Team />
      <Content />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  )
}

export default Index
