import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      jeroen: file(relativePath: { eq: "jeroen.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      johan: file(relativePath: { eq: "johan.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      paul: file(relativePath: { eq: "paul.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ingeborg: file(relativePath: { eq: "ingeborg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lars: file(relativePath: { eq: "lars.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gerard: file(relativePath: { eq: "gerard.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marcel: file(relativePath: { eq: "marcel.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold leading-9 tracking-tight sm:text-4xl">
              Our Tax and VAT experts are ready to be of service
            </h2>
            <p className="text-xl leading-7 text-gray-500">
              Each field comes with its own difficulties, deviations and
              required knowledge, and thus needs specialized experts who don't
              overlook anything. Every member of our experienced team merely
              focuses on what they’re best at. Well met.
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.jeroen.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Jeroen van der Linden</h4>
                    <p className="text-orange-500">Tax Partner</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.johan.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Johan Felius</h4>
                    <p className="text-orange-500">Tax Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.paul.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Paul Hulshof</h4>
                    <p className="text-orange-500">Tax Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.ingeborg.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Ingeborg Ahlers</h4>
                    <p className="text-orange-500">Senior Legal Manager</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.lars.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Lars Lommen</h4>
                    <p className="text-orange-500">Senior Tax Manager</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.gerard.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Gerard Brand</h4>
                    <p className="text-orange-500">Senior VAT Manager</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <Img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    fluid={data.marcel.childImageSharp.fluid}
                    alt=""
                  />
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h4>Marcel de Jong</h4>
                    <p className="text-orange-500">Tax Compliance Officer</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
