import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      trap: file(relativePath: { eq: "trap.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 1035) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img
            className="object-cover w-full h-56 lg:absolute lg:h-full"
            fluid={data.trap.childImageSharp.fluid}
            alt=""
          />
        </div>
      </div>
      <div className="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold leading-9 tracking-tight sm:text-4xl sm:leading-10">
              Get in touch with our tax and legal experts
            </h2>
            <p className="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
              Whether you already know what service you need or still have
              questions about how we can be of service; we’d love to hear from
              you. Find out if we can assist you with your issue(s). Give us a
              call, send an email or fill in the form below.
            </p>
            <div className="mt-12">
              <form
                name="Footer Form"
                method="POST"
                data-netlify="true"
                action="/thank-you/"
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <input type="hidden" name="form-name" value="Footer Form" />
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    First name
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="first_name"
                      name="first_name"
                      required
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-input"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="last_name"
                      name="last_name"
                      required
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-input"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Company
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="company"
                      name="company"
                      required
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-input"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Email
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-input"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="phone_number"
                      name="phone_number"
                      required
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-input"
                      placeholder="+1 (555) 987-6543"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Message
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="block w-full px-4 py-3 transition duration-150 ease-in-out form-textarea"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <span className="inline-flex w-full rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded-md hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700"
                    >
                      Let's talk
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
