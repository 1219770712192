import React from "react"

const FAQ = () => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl px-4 pt-12 pb-16 mx-auto sm:pt-16 sm:pb-20 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <h2 className="text-3xl font-extrabold leading-9 text-gray-900">
          Frequently asked questions
        </h2>
        <div className="pt-10 mt-6 border-t-2 border-gray-100">
          <dl className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Where are you established?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    TGS lime tree is based in Amsterdam, but serving clients
                    from all over the globe. Our address is Sarphatistraat 370,
                    1018 GW Amsterdam.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Are you a recognized tax advisory firm?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Yes, TGS lime tree is a tax and legal firm recognized by the
                    Dutch Association of Tax Advisors (in Dutch: de Nederlandse
                    Orde van Belastingadviseurs or NOB).
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  What kind of companies do you assist?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                  Our tax and legal services are available by all sorts of companies. We are happy to help small- and medium-sized multinationals.</p>
                </dd>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  What are the costs of your services?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Most of our services are charged on the basis of hourly
                    rates. Our current fee structure varies between € 145 for
                    junior staff and € 320 for partner and director staff.
                    Please note that all amounts mentioned are excluding Dutch
                    VAT.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Does TGS lime tree outsource its Dutch tax returns?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    No. Some competitors outsource their Dutch tax returns to
                    India, or rely solely on their accounting software. At TGS
                    lime tree, all tax returns are prepared handmade -bespoke-
                    in the Netherlands, using state of the art software.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Is TGS lime tree able to help me anywhere in the world?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Yes. We can assist you almost everywhere in Europe, and
                    beyond. We don’t think in borders, we think in solutions.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default FAQ
